import { Container, Grid, Box } from "@material-ui/core";
import { RootStateOrAny, useSelector } from "react-redux";
import Logo from "src/components/elements/logo";
import Secondary from "src/components/elements/navigation/Secondary";
import {
  menuCardLocation
} from "../../../constants/routing";


const Footer = () =>  {
  const menuCardItems = useSelector(
    (state: RootStateOrAny) => state.menuCardItems
  );

  const contact = useSelector(
    (state: RootStateOrAny) => state.components.contact
  );
  return (
    <footer>
      <Container>
        <Grid className="d-flex justify-content-between flex-wrap">
          <Grid
            item
            md={4}
            sm={12}
            className="d-flex justify-content-center flex-column align-items-center mt-3"
          >
            <Logo />
          </Grid>
          <Grid className="mt-5 w-100" item md={2} sm={12}>
            <h4>Menu's</h4>
            <ul>
              {menuCardItems &&
                menuCardItems.map((item, i) => {

                    if (item.acf.gerechten_selecteren.length > 0) {
                      return (
                        <li>
                          <a href={menuCardLocation + "/" + item.id}>
                            {item.acf.menu_titel}
                          </a>
                        </li>
                      );
                    
                  }
                })}
            </ul>
          </Grid>
          <Grid className="mt-5 w-100" item md={2} sm={12} xs={12}>
            <h4>Extra</h4>
            <Secondary />
          </Grid>
          <Grid className="mt-5  w-100" item md={3} sm={12}>
            <h4>Contact</h4>
            <ul>
              <li>
                <a href="tel:003289217090">{contact.phone}</a>
              </li>
              <li>
                <a href="mailto:info@lapiazza.be">{contact.email}</a>
              </li>
              <li>
                <a href={"https://goo.gl/maps/De4HxYQk1maeVBFz5"}>{contact.adres}</a>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Box className="d-flex justify-content-center credits text-center">
          © Copyright 2020. methods . All rights reserved.
        </Box>
      </Container>
    </footer>
  );
}

export default Footer