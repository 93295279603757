import { Helmet } from "react-helmet";
import { IProps } from "./type";
import sanitizeHtml from "sanitize-html";

const Head = ({ title, description }: IProps) => {
  const descriptionSanitized = sanitizeHtml(description, {
    allowedTags: [],
    allowedAttributes: {},
  });

  return (
    <Helmet>
      <title>{title} | La Piazza Genk - www.lapiazzagenk.be</title>c
      <meta charSet="UTF-8" />
      <meta name="description" content={descriptionSanitized} />
      <meta
        name="keywords"
        content={title && title.split(" ") + "," + descriptionSanitized.split(" ")}
      />
      <meta name="author" content={title} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
  );
};

export default Head;
