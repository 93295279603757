import {
  SET_SINGLE_CARD,
  SET_ALL_DISH_ROUTES,
  SET_ALL_DISHES,
  SET_BANNERS,
  SET_BURGER_CLICK,
  SET_INVIEW,
  SET_MENUCARDS,
  SET_ACTIVE_MENU,
  SET_NAV_BAR_BOOL,
  SET_OPENINGSHOURS,
  SET_SINGLE_ITEM,
  SET_SPOTLIGHT,
  SUB_MENU_CONTROLLER,
  SET_COMPONENT_ITEMS,
  SET_POPUP_DIALOG_CONTENT,
  SET_DIALOG_POPUP_OPEN,
  SET_SELECTED_CARD_ITEMS,
  SET_GALLERY_ITEMS
} from "../constants";

export const setBannerItems = (bannerItems) => {
  return {
    type: SET_BANNERS,
    payload: bannerItems,
  };
};

export const setBurgerClick = (click) => {
  return {
    type: SET_BURGER_CLICK,
    payload: click,
  };
};

export const setInView = (inView) => {
  return {
    type: SET_INVIEW,
    payload: inView,
  };
};

export const setMenuCardItems = (menuCardItems) => {
  return {
    type: SET_MENUCARDS,
    payload: menuCardItems,
  };
};

export const setActiveMenuCard = (menuCardName) => {
  return {
    type: SET_ACTIVE_MENU,
    payload: menuCardName,
  };
};

export const setNavBarBool = (e) => {
  return {
    type: SET_NAV_BAR_BOOL,
    payload: e,
  };
};

export const setOpeningsHoursItems = (openingsHours) => {
  return {
    type: SET_OPENINGSHOURS,
    payload: openingsHours,
  };
};

export const openPopupDialog = (payload) => {
  return {
    type: SET_DIALOG_POPUP_OPEN,
    payload: payload,
  };
};

export const setPopupDialogContent = (click) => {
  return {
    type: SET_POPUP_DIALOG_CONTENT,
    payload: click,
  };
};

export const setComponentItems = (items: any) => {
  return {
    type: SET_COMPONENT_ITEMS,
    payload: items,
  };
};

export const setShowMenu = (bool: boolean) => {
  return {
    type: SUB_MENU_CONTROLLER,
    payload: bool,
  };
    
};

export const setSingleItem = (item) => {
  return {
    type: SET_SINGLE_ITEM,
    payload: item,
  };
};

export const setSingleCard = (item) => {
  return {
    type: SET_SINGLE_CARD,
    payload: item,
  };
};

export const setAllDishes = (items) => {
  return {
    type: SET_ALL_DISHES,
    payload: items,
  };
};

export const setSpotlightItems = (spotlightItems) => {
  return {
    type: SET_SPOTLIGHT,
    payload: spotlightItems,
  };
};

export const setAllDishRoutes = (items) => {
  return {
    type: SET_ALL_DISH_ROUTES,
    payload: items,
  };
};


export const setSelectedCardItems = (items) => {
  return {
    type: SET_SELECTED_CARD_ITEMS,
    payload: items,
  }
}

export const setGalleryItems = (items) => {
  return {
    type: SET_GALLERY_ITEMS,
    payload: items,
  }
}


export const setAlertItems = (items) => {
  return {
    type: SET_GALLERY_ITEMS,
    payload: items,
  }
}


