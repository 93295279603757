
import { InView } from 'react-intersection-observer';
import { setInView } from '../../../store/actions'
import { useDispatch } from 'react-redux';

export default function InViewTracker(props) {
    const {string} = props;
    const dispatch = useDispatch();

    return (
        <InView delay={0} threshold={0} as="div" onChange={(inView) => inView && dispatch(setInView(string))}><></></InView>
    )
}
