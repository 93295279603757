import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getAllBanners, getAllGalleryImages } from "../services/get";
import { setBannerItems, setGalleryItems } from "../store/actions";

export function UseBanners() {
  const dispatch = useDispatch();
  useEffect(() => {
    getAllBanners().then((result) => dispatch(setBannerItems(result)));
    getAllGalleryImages().then((result) => dispatch(setGalleryItems(result)));
  }, []);
  return <></>;
}
