import { Grid } from "@material-ui/core";
import Markdown from "src/components/elements/markdown";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { dishLocation } from "src/constants/routing";
import ScrollAnimation from "react-animate-on-scroll";
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    minHeight: "10rem",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

export default function Item(props) {
  const { title, description, price, thumbnail, id } = props;
  const classes = useStyles();
  const clickHandler = () => {
    window.location.href = dishLocation + "/" + id;
  };
  const randomNumber = Math.floor(Math.random() * 10) / 10;
  return (


    <Grid item xs={12} sm={6} md={4} xl={3} onClick={clickHandler}>
      <ScrollAnimation
        animateOnce={true}
        animateIn="fadeIn"
        duration={randomNumber}
      >
        <Card className={classes.root}>
          <CardActionArea className="h-100">
            <CardMedia
              component="img"
              alt="title"
              height="150"
              image={thumbnail}
              title="title"
            />
            <CardContent className={classes.card}>
              <Typography gutterBottom variant="h5" component="h3">
                {title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <Markdown input={description.length > 300 ? description.substr(0, 300) + "..." : description} />
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="span"
              >
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card></ScrollAnimation>
    </Grid>
  );
}
