import { Container } from "@material-ui/core";
import { RootStateOrAny, useSelector } from "react-redux";
import ImageGridList from "src/components/elements/gridlist";
import { SRLWrapper } from "simple-react-lightbox";
export default function Gallery() {
  const gallery = useSelector(
    (state: RootStateOrAny) => state.components.gallery
  );
  return (
    <>
      <Container>
        <h2 className="pt-5">{gallery.title}</h2>
      </Container>
      <SRLWrapper>
        <ImageGridList />
      </SRLWrapper>
    </>
  );
}
