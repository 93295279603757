
import { Box } from '@material-ui/core';
import React from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'


export default function OpeningsHours() {
    const openingsHoursItems = useSelector((state: RootStateOrAny) => state.components.openingsHours)

    return (
        <div className="_contact__openingshours">
            <h4 className="text-center">Openingsuren</h4>
          
            <ul>
                {openingsHoursItems.reverse().map((item,i) => {
                    const { titel, closed, openings_uren_indien_niet_gesloten, extra_text } = item.acf;
                      <Box key={i} className="text-center mb-2">
                            {extra_text}
                     </Box>
                     if (openings_uren_indien_niet_gesloten && !closed) {
                        return <Box key={i}>
                            <li className="day">{titel}</li>
                            <li className="hours_or_closed">{openings_uren_indien_niet_gesloten}</li>
                        </Box>
                    }
                    if (closed) {
                        return (<Box key={i}>
                            <li className="day">{titel}</li>
                            <li className="hours_or_closed">Gesloten</li>
                        </Box>)
                    }
                   

                })}</ul>
        </div>
    )
}
