import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  homeLocation,
  aboutLocation,
  contactLocation,
  privacyLocation,
  cookieLocation,
  menuCardLocation,
  tourLocation,
  galleryLocation,
  siteMapLocation,
} from "../constants/routing";
import Home from "../pages/home";
import About from "../pages/about";
import Menu from "../pages/menu";

import Contact from "../pages/contact";
import Privacy from "../pages/privacy-policy";
import Cookies from "../pages/cookies";
import { RootStateOrAny, useSelector } from "react-redux";
import menuCardDetail from "../pages/menu/detail";
import dishDetail from "../pages/dish/index";
import Tour from "src/pages/tour";
import Gallery from "src/pages/gallery";
// import SiteMap from "src/pages/site-map"
import Error from "src/pages/not-found";
import SiteMap from "src/pages/site-map";

export default function Routes() {
  const dishesRoutes = useSelector(
    (state: RootStateOrAny) => state?.dishesRoutes
  );
  const menuCardItems = useSelector(
    (state: RootStateOrAny) => state?.menuCardItems
  );
  return (
    <BrowserRouter>
      <Switch>
        {menuCardItems &&
          menuCardItems.map((item, i) => {
            return (
              <Route
                exact
                key={i}
                path={"/menu/:" + item.id + "/"}
                component={menuCardDetail}
              />
            );
          })}
        {menuCardItems &&
          dishesRoutes.map((item, i) => {
            return (
              <Route
                exact
                key={i}
                path={"/gerechten/:" + item.id + "/"}
                component={dishDetail}
              />
            );
          })}
        <Route exact path={homeLocation} render={() => <Home />} />
        <Route exact path={menuCardLocation} render={() => <Menu />} />
        <Route exact path={aboutLocation} render={() => <About />} />
        <Route exact path={galleryLocation} render={() => <Gallery />} />
        <Route exact path={contactLocation} render={() => <Contact />} />
        <Route exact path={privacyLocation} render={() => <Privacy />} />
        <Route exact path={cookieLocation} render={() => <Cookies />} />
        <Route exact path={siteMapLocation} render={() => <SiteMap />} />

        <Route
          path="/admin"
          component={() => {
            window.location.href = "https://api.lapiazzagenk.be/login";
            return null;
          }}
        />
        <Route
          path="/gerechten"
          component={() => {
            window.location.href = menuCardLocation;
            return null;
          }}
        />
         <Route
          path="/admin"
          component={() => {
            window.location.href = "https://api.lapiazzagenk.be/login";
            return null;
          }}
        />

        <Route component={Error} />
      </Switch>
    </BrowserRouter>
  );
}
