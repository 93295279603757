module.exports = {
    SET_BANNERS: "SET_BANNERS",
    SET_INVIEW: "SET_INVIEW",
    SET_SPOTLIGHT: "SET_SPOTLIGHT",
    SET_BURGER_CLICK: "SET_BURGER_CLICK",
    SET_OPENINGSHOURS: "SET_OPENINGSHOURS",
    SET_MENUCARDS: "SET_MENUCARDS",
    SET_MENUCARDNAMES: "SET_MENUCARDNAMES",
    SET_DIALOG_OPEN: "SET_DIALOG_OPEN",
    SET_DIALOG_CONTENT: "SET_DIALOG_CONTENT",
    SET_ACTIVE_MENU: "SET_ACTIVE_MENU",
    SET_SINGLE_ITEM: "SET_SINGLE_ITEM",
    SET_SINGLE_CARD: "SET_SINGLE_CARD",
    SET_NAV_BAR_BOOL: "SET_NAV_BAR_BOOL",
    SET_DIALOG_POPUP_OPEN: "SET_DIALOG_POPUP_OPEN",
    SET_POPUP_DIALOG_CONTENT: "SET_POPUP_DIALOG_CONTENT",
    SUB_MENU_CONTROLLER: "SUB_MENU_CONTROLLER",
    SET_COMPONENT_ITEMS: "SET_COMPONENT_ITEMS",
    SET_ALL_DISHES: "SET_ALL_DISHES",
    SET_ALL_DISH_ROUTES: "SET_ALL_DISH_ROUTES",
    SET_SELECTED_CARD_ITEMS: "SET_SELECTED_CARD_ITEMS",
    SET_GALLERY_ITEMS: "SET_GALLERY_ITEMS",
    SET_ALERT_ITEMS: "SET_ALERT_ITEMS"
}