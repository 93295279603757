import {
    SET_SINGLE_CARD,
    SET_ALL_DISH_ROUTES,
    SET_ALL_DISHES,
    SET_BANNERS,
    SET_BURGER_CLICK,
    SET_DIALOG_CONTENT,
    SET_INVIEW,
    SET_MENUCARDS,
    SET_MENUCARDNAMES,
    SET_ACTIVE_MENU,
    SET_NAV_BAR_BOOL,
    SET_DIALOG_OPEN,
    SET_OPENINGSHOURS,
    SET_SINGLE_ITEM,
    SET_SPOTLIGHT,
    SET_SELECTED_CARD_ITEMS,
    SET_COMPONENT_ITEMS,
    SET_POPUP_DIALOG_CONTENT,
    SET_DIALOG_POPUP_OPEN,
    SET_GALLERY_ITEMS
} from "../constants";

import { loadState } from "../localstorage";
import { withReduxStateSync } from "redux-state-sync";

const mainReducer = (state = loadState(), action) => {
    switch (action.type) {
        case SET_INVIEW: {
            return { ...state, inView: action.payload };
        }
        case SET_BANNERS: {
            return { ...state, bannerItems: action.payload };
        }
        case SET_SPOTLIGHT: {
            return { ...state, spotlightItems: action.payload };
        }
        case SET_BURGER_CLICK: {
            return { ...state, burger: action.payload };
        }
        case SET_OPENINGSHOURS: {
            return { ...state, openingsHoursItems: action.payload };
        }
        case SET_MENUCARDS: {
            return { ...state, menuCardItems: action.payload };
        }
        case SET_MENUCARDNAMES: {
            return { ...state, menuCardNames: action.payload };
        }
        case SET_DIALOG_OPEN: {
            return { ...state, setDialog: action.payload };
        }
        case SET_DIALOG_POPUP_OPEN: {
            return { ...state, setPopupDialog: action.payload };
        }
        case SET_DIALOG_CONTENT: {
            return { ...state, dialogContent: action.payload };
        }
        case SET_POPUP_DIALOG_CONTENT: {
            return { ...state, popupDialogContent: action.payload };
        }
        case SET_ACTIVE_MENU: {
            return { ...state, activeMenuCard: action.payload };
        }
        case SET_SINGLE_CARD: {
            return { ...state, singleCard: action.payload };
        }
        case SET_SINGLE_ITEM: {
            return { ...state, singleItem: action.payload };
        }
        case SET_NAV_BAR_BOOL: {
            return { ...state, navBar: action.payload };
        }
        case SET_COMPONENT_ITEMS: {
            return { ...state, components: action.payload };
        }
        case SET_ALL_DISHES: {
            return { ...state, dishes: action.payload };
        }
        case SET_ALL_DISH_ROUTES: {
            return { ...state, dishesRoutes: action.payload };
        }
        case SET_SELECTED_CARD_ITEMS: {
            return { ...state, selectedCardItems: action.payload };
        }
        case SET_GALLERY_ITEMS: {
            return { ...state, galleryItems: action.payload }
        }
        default:
            return state;
    }
};

export default withReduxStateSync(mainReducer);
