import { RootStateOrAny, useSelector } from "react-redux";
import Slider from "react-slick";
import { dishLocation, menuCardLocation } from "src/constants/routing";
import Slide from "./slide";

export default function HeroSlider() {
  const bannerItems = useSelector((state: RootStateOrAny) => state.bannerItems);

  // create settings for slider component
  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
  };

  return (
    <div className="top_mast_slider">
      <Slider {...settings}>
        {bannerItems &&
          bannerItems.map((item, i) => {
            const {
              title,
              description,
              button_text,
              button_link_extern,
            } = item.acf;
            const picture = item.acf.foto.sizes;
            const postType = item.acf.button_link_intern.post_type;
            const buttonLinkInternalId = item.acf.button_link_intern.ID;

            const buttonLink =
              postType === "menus"
                ? menuCardLocation + "/" + buttonLinkInternalId
                : postType === "dishes"
                ? dishLocation + "/" + buttonLinkInternalId
                : button_link_extern;

            return (
              <Slide
                key={i}
                title={title}
                subtitle={description}
                buttontext={button_text}
                buttonhref={buttonLink}
                picture={picture}
              />
            );
          })}
      </Slider>
    </div>
  );
}
