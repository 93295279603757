import { Container } from "@material-ui/core";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Markdown from "src/components/elements/markdown";
import Head from "src/components/meta/head";
import Footer from "src/components/sections/footer";
import Header from "src/components/sections/header";
import { setInView } from "src/store/actions";

const Cookies: React.FC = () => {
  const cookies = useSelector(
    (state: RootStateOrAny) => state.components.cookies
  );
  const dispatch = useDispatch();
  dispatch(setInView(""));

  return (
    <>
      <Head title={cookies.title} description={cookies.description} />
      <Header front={true} />
      <Container className="my-5">
        <h2>{cookies.title}</h2>
        <Markdown input={cookies.description} />
      </Container>
      <Footer />
    </>
  );
};

export default Cookies;
