import { Box, Container, Grid } from "@material-ui/core";
import { useSelector, RootStateOrAny} from "react-redux";
import Markdown from "src/components/elements/markdown";
import { useEffect } from "react";
import Item from "./item";
import { menuCardLocation } from "src/constants/routing";
import BreadCrumbs from "src/components/elements/breadcrumbs";

const Menu = (props) => {
  const menus = useSelector((state: RootStateOrAny) => state.components.menus);
  const menuCardItems = useSelector(
    (state: RootStateOrAny) => state.menuCardItems
  );
  const menuCardNames = [];

  useEffect(() => {
    if (menuCardItems) {
      menuCardItems.map((el) => {
        if (!el.acf.gerechten_selecteren === false) {
          return menuCardNames.push(el);
        }
      });
    }
  }, [menuCardItems, menuCardNames]);

  return (
    <section>
      <Container className="my-5">
        {props.front && (
          <BreadCrumbs menu={menuCardLocation} menuText={"Menu"} />
        )}
        <h2>{menus.title}</h2>
        <Box className="pb-4">
          <Markdown input={menus.description} />
        </Box>

        <Grid container spacing={2}>
          {menuCardItems?.map((item, i) => {
            const { menu_titel, menu_card_image } = item.acf;
            if (item.acf.gerechten_selecteren.length > 0) {
              return (
                <Item
                  key={i}
                  title={menu_titel}
                  image={menu_card_image.sizes["medium"]}
                  link={item.id}
                />
              );
            }
          })}
        </Grid>
      </Container>
    </section>
  );
};

export default Menu;
