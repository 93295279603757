/* eslint-disable array-callback-return */
import { Box, Container, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Parallax } from "react-parallax";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { UseMenu } from "src/api/menu";
import Loader from "src/components/elements/loader";
import Footer from "src/components/sections/footer";
import Header from "src/components/sections/header";
import DishItem from "src/components/sections/dish/item";

import BreadCrumbs from "src/components/elements/breadcrumbs";
import { menuCardLocation } from "src/constants/routing";
import { getDish } from "src/services/get";
import Head from "src/components/meta/head";
import { setInView } from "src/store/actions";
import Markdown from "src/components/elements/markdown";

const Detail = () => {
  const menus = useSelector((state: RootStateOrAny) => state.components.menus);
  const location = useLocation();
  const id = location.pathname.split("/");
  const card = id[2];
  const [loaded, setLoaded] = useState<boolean>(false);
  const data = useSelector((state: RootStateOrAny) => state.singleCard);
  const menuCardItems = useSelector(
    (state: RootStateOrAny) => state.menuCardItems
  );
  const [dishesState, setDishesState] = useState<any>();
  const dishes = [];
  const dispatch = useDispatch();
  dispatch(setInView("menu"));
  useEffect(() => {
    menuCardItems?.map((item) => {
      if (item.id.toString() === card) {
        !item.acf.gerechten_selecteren
          ? (window.location.href = "/oops")
          : item.acf.gerechten_selecteren.map((id) => {
              getDish(id).then((item) => {
                dishes.push({ id, item });
                setDishesState(dishes);
                setLoaded(true);
              });
            });
      }
    });
  }, []);

  if (loaded) {
    return (
      <>
        <>
          <Head title={data.acf.menu_titel} description={menus.description} />
          <Header front={true} />
          <Box style={{ maxHeight: "33vh", overflow: "hidden" }}>
            <Parallax
              style={{ height: "800px", top: "-10vh" }}
              bgImage={
                data.acf.menu_card_image.sizes["twentytwenty-fullscreen"]
              }
              bgImageAlt={data.acf.menu_titel}
              strength={90}
            >
              <div style={{ height: "33vh" }} />
            </Parallax>
          </Box>

          <Container className="my-5">
            <BreadCrumbs
              menu={menuCardLocation}
              menuText={"Menu"}
              sub={card}
              subText={data.acf.menu_titel}
            />

            <h2 className="py-3">{data.acf.menu_titel}</h2>
            <Box className="pb-3">
               <Markdown input={data.acf.caption_description}/>
            </Box>
           
            <Grid container spacing={2}>
              {dishesState &&
                dishesState.map((dish) => {
                  return (
                    <DishItem
                      title={dish.item.title}
                      description={dish.item.description}
                      thumbnail={dish.item.dish_image.sizes["medium_large"]}
                      id={dish.id}
                    />
                  );
                })}
            </Grid>
          </Container>
          <Footer />
        </>
      </>
    );
  } else {
    return (
      <>
        <Loader />
        <UseMenu id={card} />
      </>
    );
  }
}

export default Detail;