import { homeLocation } from 'src/constants/routing';
import {ReactComponent as LogoSvg} from '../../../assets/svg/logo_w.svg'


const Logo :React.FC<{}> = () =>{
    return (
        <a href={homeLocation}>
             <LogoSvg/>
        </a>
    )
}


export default Logo;