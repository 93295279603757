import {IButton} from './type'

const Button: React.FC<IButton> = ({text, link, className, children = "Click"}) => {
    const value = text || children;
    const clickHandler = () => {
        window.location.href = link
    }

    const buttonText = value ? value : "Verder gaan";

    return (
        <button onClick={clickHandler} className={!className ? "btn" : className}>
            {buttonText}
        </button>
    )
}

export default Button;