import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Head from "src/components/meta/head";
import Footer from "src/components/sections/footer";
import Header from "src/components/sections/header";
import { setInView } from "src/store/actions";
import AboutComponent from "../../components/sections/about";

const About: React.FC = () => {
  const about = useSelector((state:RootStateOrAny) => state.components.about)
  const dispatch = useDispatch();
  dispatch(setInView("about"));
  return (
    <>
    
      <Head title={about.title} description={about.description} />
      <Header front={true} />
      <AboutComponent />
      <Footer />
    </>
  );
};

export default About;
