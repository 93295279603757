import React from 'react';

type Notification = {
    id: number;
    acf: {
        bericht: string;
        visible_from: string;
        visible_to: string;
    }
}

type Props = {
    notifications: Notification[]
}

const NotificationBoxes: React.FC<Props> = ({ notifications = [] }) => {
    const currentDate = new Date();

    const filteredNotifications = notifications?.filter(notification => {
        const { visible_from, visible_to } = notification.acf;
        const visibleFromDate = new Date(visible_from.split('/').reverse().join('-'));
        const visibleToDate = visible_to ? new Date(visible_to.split('/').reverse().join('-')) : null;

        const isAfterVisibleFrom = currentDate >= visibleFromDate;
        const isBeforeVisibleTo = visibleToDate ? currentDate <= visibleToDate : true;

        return isAfterVisibleFrom && isBeforeVisibleTo;
    });

    return (
        <>
            {filteredNotifications?.map(notification => (
                <NotificationBox key={notification.id} acf={notification.acf} />
            ))}
        </>
    );
};

const NotificationBox = ({ acf }) => {
    const { bericht } = acf;
    return <div className='notification-box'>{bericht}</div>;
}

export default NotificationBoxes;
