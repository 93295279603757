import axios from "axios";

export const getComponent = async (id: number) => {
  return axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/components/${id}`
    )
    .then((res) => {
      return res.data.acf;
    });
};

export const getOpeningsHours = async () => {
  return axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/closinghours/`
    )
    .then((res) => {
      return res.data;
    });
};

export const getAllComponents = async () => {
  return axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/components?per_page=100`
    )
    .then((res) => {
      return res.data;
    });
};

export const getAllBanners = async () => {
  return axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/banners?per_page=100`
    )
    .then((res) => {
      return res.data;
    });
};

export const getDish = async (id: number) => {
  return axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/dishes/${id}`
    )
    .then((res) => {
      return res.data.acf;
    });
};

export const getAllMenus = async () => {
  return axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/menus?per_page=100`
    )
    .then((res) => {
      return res.data;
    });
};


export const getAllGalleryImages= async () => {
  return axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/gallerij?per_page=100`
    )
    .then((res) => {
      return res.data;
    });
};


export const getAllDishes = async () => {
  return axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/dishes?per_page=100`
    )
    .then((res) => {
      return res.data;
    });
};

export const getAllNotifications = async () => {
  return axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/my_notifications?per_page=100`
    )
    .then((res) => {
      return res.data;
    });
};




export const getAsyncComponents = async () => {
  const about = await getComponent(40);
  const spotlight = await getComponent(41);
  const menus = await getComponent(42);
  const gallery = await getComponent(43);
  const tour = await getComponent(44);
  const contact = await getComponent(45);
  const privacyPolicy = await getComponent(493);
  const cookies = await getComponent(494);
  const openingsHours = await getOpeningsHours();
  const notifications = await getAllNotifications();

  const components = {
    openingsHours,
    spotlight,
    menus,
    gallery,
    about,
    contact,
    tour,
    privacyPolicy,
    cookies,
    notifications
  };

  return components;
};

export const getAllAlerts = async () => {
  return axios.get(`${process.env.REACT_APP_MAIN_API +
    process.env.REACT_APP_MAIN_JSON +
    process.env.REACT_APP_MAIN_EXTENDED_FIELD
  }/alert?per_page=100`)
}
