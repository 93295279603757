import { Container } from "@material-ui/core";
import { Parallax } from "react-parallax";
import Box from "@material-ui/core/Box";
import ScrollAnimation from "react-animate-on-scroll";
import { useHistory } from "react-router-dom";
import { aboutLocation } from "src/constants/routing";
import { RootStateOrAny, useSelector } from "react-redux";
import InViewTracker from "src/components/meta/inViewTracker";
import Markdown from "src/components/elements/markdown";

export default function About() {
  const about = useSelector((state: RootStateOrAny) => state.components.about);
  const router = useHistory();

  const handleClick = () => {
    router.push(aboutLocation);
  };

  return (
    <section className="py-5 about">
      <Container id="about">
        <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={1}>
          <h2 onClick={() => handleClick} className="pt-5">
            {about.title}
          </h2>
          <Markdown input={about.description} />
        </ScrollAnimation>
        <Box
          style={{ position: "relative", height: "600px", marginTop: "75px" }}
        >
          <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={0.4}>
            <Box style={{ position: "absolute", left: "0", width: "60%" }}>
              <Parallax
                bgImage={about.picture1}
                bgImageAlt="about_image_1"
                strength={150}
              >
                <div style={{ height: "400px" }} />
              </Parallax>
            </Box>
          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={0.8}>
            <Box
              style={{
                position: "absolute",
                right: "0",
                top: "50px",
                width: "60%",
              }}
            >
              <Parallax
                bgImage={about.picture2}
                bgImageAlt="about_image_2"
                strength={100}
              >
                <div style={{ height: "500px" }} />
              </Parallax>
            </Box>
          </ScrollAnimation>
        </Box>
        <InViewTracker string="about" />
      </Container>
    </section>
  );
}
