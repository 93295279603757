import { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSingleCard } from "src/store/actions";

export function UseMenu({id}) {
    const dispatch = useDispatch();
    const menuCardItems = useSelector(
    (state: RootStateOrAny) => state.menuCardItems
    );
    useEffect(() => {
        menuCardItems && menuCardItems.map((card) => {
        if (card.id.toString() === id) { 
          return dispatch(setSingleCard(card));
        }
      });
      },[id])
  return <></>;
}
