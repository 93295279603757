import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import { Grid, Typography } from "@material-ui/core";
import { menuCardLocation } from "src/constants/routing";
import ScrollAnimation from "react-animate-on-scroll";
export default function Detail(props) {
  const { title, image, link } = props;

  const clickHandler = () => {
    window.location.href = menuCardLocation + "/" + link;
  };
  const randomNumber = Math.floor(Math.random() * 10) / 10;
  return (
    <Grid
      className="onHover"
      onClick={clickHandler}
      item
      xs={12}
      sm={6}
      md={4}
      xl={4}
    >
      <ScrollAnimation
        animateOnce={true}
        animateIn="fadeIn"
        duration={randomNumber}
      >
        <Card className="d-flex">
          <div className="d-flex flex-column">
            <CardContent style={{background: 'rgba(0, 0, 0, 0.3)', padding: '.2% 1%'}} className="position-absolute">
              <Typography style={{paddingBottom:0}} className="text-white" component="h5" variant="h5">
                {title}
              </Typography>
            </CardContent>
          </div>
          <CardMedia
            style={{ minHeight: "200px" }}
            className="w-100"
            image={image}
            title={title}
          />
        </Card>
        
      </ScrollAnimation>
    </Grid>
  );
}
