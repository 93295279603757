import Header from "../../components/sections/header";
import Footer from "../../components/sections/footer";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Typography } from "@material-ui/core";
import Head from "src/components/meta/head";
// import Joey from 'giphy.gif'
const Error = () => {
  return (
    <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={0.1}>
      <Head title="404 Page" description={"Page Not Found"} />
      <Header front={true} />

      <Container
        style={{ marginTop: "10em", paddingBottom: "5em", textAlign: "center" }}
      >
        <img
          width="50%"
          alt="404"
          src={process.env.PUBLIC_URL + "/img/giphy.gif"}
        ></img>
        <Typography>Pagina Bestaat niet!</Typography>
      </Container>
      <Footer />
    </ScrollAnimation>
  );
};

export default Error;
