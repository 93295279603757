import { Box, Button } from "@material-ui/core";
import { useState, useRef } from "react";
import { postContactForm } from "src/services/post";
import ReCAPTCHA from "react-google-recaptcha";
import { IInput, IFormComponent } from "./type";

const FormComponent:React.FC<IFormComponent> = ({front}) => {
  const [input, setInput] = useState<IInput>();
  const [googleFormConfirm, setGoogleFormConfirm] = useState(false);
  const formRef = useRef(null);
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await postContactForm(formRef.current);
    formRef.current.reset();
  };
  
  return (
    <>
      <h4>Stuur een boodschap</h4>
      <Box className="d-flex flex-column __contact_form_parent">
        <form
          onSubmit={(e) => handleSubmit(e)}
          ref={formRef}
          className="d-flex flex-column _contact__form"
        >
          <input
            onChange={handleInputChange}
            name="clientName"
            placeholder="naam"
            id="name"
            required
          ></input>
          <input
            onChange={handleInputChange}
            name="clientEmail"
            placeholder="email"
            id="email"
            autoComplete="email"
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            required
          ></input>
          <input
            onChange={handleInputChange}
            name="clientSubject"
            placeholder="onderwerp"
            id="subject"
            required
          ></input>
          <textarea
            onChange={handleInputChange}
            name="clientMessage"
            placeholder="boodschap"
            required
          ></textarea>
          <Box className="my-2">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PROD}
              onChange={() => setGoogleFormConfirm(true)}
            />
          </Box>

          <Button
            className={
              front === undefined
                ? "d-flex justify-content-center w-25"
                : "d-flex justify-content-center w-25 text-dark border-dark"
            }
            type="submit"
            disabled={!googleFormConfirm}
          >
            Verstuur
          </Button>
        </form>
      </Box>
    </>
  );
}

export default FormComponent;