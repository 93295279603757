import axios from "axios";
import Swal from "sweetalert2";

export const postContactForm = async (input) => {
  const formData = new FormData(input)
  await axios({
    method: "post",
    url:
      process.env.REACT_APP_MAIN_API +
      process.env.REACT_APP_MAIN_JSON + 
      process.env.REACT_APP_MAIN_CONTACT,
    data: formData,
    headers: { "content-type": "multipart/form-data" },
  })
    .then(() => {
      Swal.fire("Gelukt!", "Het formulier is verzonden!", "success");
    })
    .catch((error) => {
      Swal.fire(
        "Mislukt!",
        `Het formulier werd niet verzonden... ${error}`,
        "error"
      );
    });
};
