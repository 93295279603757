import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      margin: "3em 0",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: 100 + "%",
    },
  })
);
