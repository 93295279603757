import { Grid, Box } from "@material-ui/core";
import { Parallax } from "react-parallax";
import placeholder from "src/assets/ui/placeholder.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import Markdown from "src/components/elements/markdown";
import { dishLocation } from "src/constants/routing";

export default function Detail(props) {
  const { name, description, price, thumbnail, id } = props;

  const handleClick = () => {
    window.location.href = dishLocation + '/' + id;
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      className={"spotlight__item"}
      onClick={handleClick}
    >
      <Grid item xs={12} md={6}>
        <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={0.8}>
          <Box>
            <Parallax
              style={{ height: "300px" }}
              bgImage={thumbnail === undefined ? placeholder : thumbnail}
              bgImageAlt="about_image_1"
              strength={50}
            >
              <div style={{ height: "300px" }} />
            </Parallax>
          </Box>
        </ScrollAnimation>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        className={
          "d-flex justify-content-center flex-column text-center  bg-white"
        }
      >
        <h4 className="pt-1 pb-0 mb-0">{name}</h4>
        <Box className="px-2">
          <Markdown input={description.length > 300 ? description.substr(0, 300) + "..." : description} />
        </Box>
      </Grid>
    </Grid>
  );
}
