import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Head from "src/components/meta/head";
import Footer from "src/components/sections/footer";
import Header from "src/components/sections/header";
import MenuComponent from "src/components/sections/menu";
import { setInView } from "src/store/actions";
const Menu: React.FC = () => {
   const menus = useSelector((state:RootStateOrAny) => state.components.menus)
  const dispatch = useDispatch();
  dispatch(setInView("menu"));
  return (
    <>
      <Head title={menus.title} description={menus.description} />
      <Header front={true} />
      <MenuComponent front={true} />
      <Footer />
    </>
  );
};

export default Menu;
