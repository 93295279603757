import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getAllMenus, getDish } from "src/services/get";
import {
  setActiveMenuCard,
  setAllDishes,
  setMenuCardItems,
} from "src/store/actions";

export function UseMenus() {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState<boolean>(false);
  const activeMenuCard = useSelector(
    (state: RootStateOrAny) => state.activeMenuCard
  );
  useEffect(() => {
    if (!loaded) {
      getAllMenus().then((result) => {
        dispatch(setMenuCardItems(result));
        if (activeMenuCard && activeMenuCard[2] !== false) {
          activeMenuCard &&
            activeMenuCard[2].map((item) => {
              getDish(item).then((res) => setAllDishes(res));
            });
        }
      });
      setLoaded(true);
    }
  }, [loaded]);

  return <></>;
}
