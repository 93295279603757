import { Container, Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { UseDish } from "src/api/dish";
import Loader from "src/components/elements/loader";
import Markdown from "src/components/elements/markdown";
import Footer from "src/components/sections/footer";
import Header from "src/components/sections/header";
import BreadCrumbs from "src/components/elements/breadcrumbs";
import { menuCardLocation } from "src/constants/routing";
import Head from "src/components/meta/head";
import { setInView } from "src/store/actions";
import { SRLWrapper } from "simple-react-lightbox";
export default function Detail() {
  const location = useLocation();
  const id = location.pathname.split("/");
  const dish = id[2];
  const data = useSelector((state: RootStateOrAny) => state.singleItem);
  const [loaded, setLoaded] = useState<boolean>(false);
  const dispatch = useDispatch();
  dispatch(setInView("menu"));
  useEffect(() => {
    if (data) {
      if (dish === data.id) {
        setLoaded(true);
      }
    }
  }, [data, dish]);

  if (loaded) {
    return (
      <>
        <Head title={data.item.title} description={data.item.description} />
        <Header front={true} />
        <Container className="my-5">
          <BreadCrumbs
            menu={menuCardLocation}
            menuText={"Menus"}
            sub={dish}
            subText={data.item.title}
          />

          <Grid container className="py-5">
            <Grid item md={6}>
               <SRLWrapper>
              <img
                alt={data.item.title}
                width="100%"
                src={data.item.dish_image.sizes["medium_large"]}
              ></img>
              </SRLWrapper>
            </Grid>
            <Grid md={6} className="d-flex flex-column align-content-start">
              <Grid item md={12} className="px-5">
                <Typography
                  className="py-3 text-left"
                  variant="h3"
                  component="h3"
                >
                  {data.item.title}
                </Typography>

                <Markdown input={data.item.description} />
              </Grid>
              {/* <Grid item md={12} className="px-5">
                <Typography className="py-3" variant="h5" component="span">
                  € {data.item.price}
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
        </Container>

        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Loader />
        <UseDish id={dish} />
      </>
    );
  }
}
