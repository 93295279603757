import { Container } from "@material-ui/core";

const Loader:React.FC<{}> = () => {
  return (
    <Container>
      <div className="loading_screen">
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
             <h5 className="text-center">Even Wachten a.u.b...</h5>
      </div>
    </Container>
  );
};

export default Loader;
