import { Container, Grid } from '@material-ui/core'
import React from 'react'
import Footer from 'src/components/sections/footer'
import Header from '../../components/sections/header'
import Form from '../../components/elements/form'
import Map from '../../components/elements/map'
import { setInView } from 'src/store/actions'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import Head from 'src/components/meta/head'
import Markdown from 'src/components/elements/markdown'
const Contact:React.FC = () => {
    const contact = useSelector((state:RootStateOrAny) => state.components.contact)
    const dispatch = useDispatch();
    dispatch(setInView("contact"));
    return (
           <>
           <Head title={contact.title} description={contact.description} />
            <Header front={true} />
            <Container className="my-5">
                <h2 className="pt-5">Contact Pagina</h2>
              
                <Grid container spacing={3}>
                    <Grid className="text-center" item xs={12} sm={12} md={12} xl={12}>
                        <Markdown input={contact.description} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Form front={false} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Map />
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    )
}


export default Contact

