import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDish } from "src/services/get";
import { setSingleItem } from "src/store/actions";

export function UseDish({id}) {
     const dispatch = useDispatch();
     useEffect(() => {
        getDish(id).then((item)=>{
            dispatch(setSingleItem({id, item}))
        })
      },[id])
  return <></>;
}


