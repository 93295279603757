
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { RootStateOrAny, useSelector } from "react-redux";
import InViewTracker from "src/components/meta/inViewTracker";
import { isMobile } from "react-device-detect";
import {useStyles } from './style'
import Image from './image'

const GalleryList:React.FC<{}> = () => {
  const classes = useStyles();
  const galleryItems = useSelector(
    (state: RootStateOrAny) => state.galleryItems
  );

  return (
    <div className={classes.root}>
      <GridList cellHeight={300} className={classes.gridList} cols={12}>
        {galleryItems &&
          galleryItems.map((tile, i) => {
            const shownAmount = isMobile ? 4 : 12;

            if (i <= shownAmount) {
              return (
                <GridListTile
                  key={tile.acf.gallery_image.sizes["medium_large"]}
                  cols={tile.cols || isMobile ? 12 : 3}
                >
                 
                    <Image src={tile.acf.gallery_image.sizes["medium_large"]} alt={tile.title}/>
                   
         
                </GridListTile>
              );
            }
          })}
        <InViewTracker string="gallery" />
      </GridList>
    </div>
  );
}
export default GalleryList;