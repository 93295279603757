import "normalize.css";
import "animate.css";
import "bootstrap-utilities";
import "./global.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import fire, { firebaseConfig } from "./services/firebase";
import Router from "./services/routes";
import Cookie from "./components/elements/cookie";
import { UseComponents } from "./api/components";
import { RootStateOrAny, useSelector } from "react-redux";
import { UseBanners } from "./api/media";
import { UseMenus } from "./api/menus";
import { useEffect, useState } from "react";
import Loader from "./components/elements/loader";
import { UseDishes } from "./api/dishes";
import NotificationBox from "./components/elements/notification";
import NotificationBoxes from "./components/elements/notification";
// import { UseSpotlight } from './api/dishes';
function App() {
  const menuCardItems = useSelector(
    (state: RootStateOrAny) => state.menuCardItems
  );
  const components = useSelector((state: RootStateOrAny) => state.components);
  const bannerItems = useSelector((state: RootStateOrAny) => state.bannerItems);
  const spotlightItems = useSelector(
    (state: RootStateOrAny) => state.spotlightItems
  );
  const dishRoutes = useSelector((state: RootStateOrAny) => state.dishesRoutes);
  const [loaded, setLoaded] = useState(false);

  try {
    fire.initializeApp(firebaseConfig);
  } catch (err) {
    if (!/already exists/.test(err.message)) {
      console.error("Firebase initialization error", err.stack);
    }
  }

  useEffect(() => {
    if (
      components &&
      bannerItems &&
      menuCardItems &&
      spotlightItems &&
      dishRoutes
    ) {
      setLoaded(true);
    }
  }, [components, bannerItems, menuCardItems, dishRoutes, spotlightItems]);
  
  return (
    <>
      <NotificationBoxes notifications={components?.notifications} />
      <UseComponents />
      <UseBanners />
      <UseMenus />
      <UseDishes />
      {loaded ? (
        <>
          <Router />
          <Cookie />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default App;
