import Header from "../../components/sections/header";
import Footer from "../../components/sections/footer";
import Head from "src/components/meta/head";
import { isMobile } from "react-device-detect";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import Nav from "../../components/elements/navigation/Nav";
import Secondary from "../../components/elements/navigation/Secondary";
import { setInView } from "src/store/actions";
import { useDispatch } from "react-redux";

const SiteMap = () => {
  const dispatch = useDispatch();
  dispatch(setInView(""));
  return (
    <>
      <Head
        title="site-map"
        description={
          "Hier vind u een overzicht van alle links die ter beschikking zijn op deze pagina."
        }
      />
      <Header front={true} />
      <Container>
        <Box className="container p-5">
          <h4>Sitemap</h4>
          <Typography>
            Hier vind u een overzicht van alle links die ter beschikking zijn op
            deze pagina.
          </Typography>
          <Grid container spacing={isMobile ? 0 : 8} className="submenu-parent">
            <Grid item md={6} sm={12} xs={12}>
              <h4 className="mt-5 mb-0">Hoofd menu</h4>
              <Nav footerCheck={true} />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <h4 className="mt-5 mb-0">Secundair menu</h4>
              <Secondary />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Footer />
    </>
  );
};

export default SiteMap;
