import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllDishes } from "src/services/get";
import { setSpotlightItems, setAllDishRoutes } from "src/store/actions";

export function UseDishes() {
  const dispatch = useDispatch();
  
  useEffect(() => {
   const spotlightItems = [] ;
   const dishroutes = []; 
    getAllDishes().then((result) => {
        result.forEach(el => {
                dishroutes.push(el)
        })
       result.forEach(element => {
           if(element.acf.spotlight === true){

               return spotlightItems.push(element)
           }
        })
    dispatch(setAllDishRoutes(dishroutes))
    shuffleArray(spotlightItems)
    if (spotlightItems.length > 4) {
        spotlightItems.length = 4 
    }
   
    dispatch(setSpotlightItems(spotlightItems))
    });
  }, []);

  return <></>;
}


function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}