import { useEffect } from "react";

import {
  aboutLocation,
  contactLocation,
  galleryLocation,
  homeLocation,
  menuCardLocation,
  tableBooker,
  tourLocation,
} from "../../../../constants/routing";
import ListItem from "../ListItem";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { setShowMenu } from "../../../../store/actions";
import { INavProps } from "./type";
import { isMobile } from "react-device-detect";

const Nav = (props: INavProps) => {
  const { className, footerCheck } = props;
  const dispatch = useDispatch();

  //Sets Global State To True If Mouse Hovers Over NavBar
  const hoverOverNavBar = useSelector(
    (state: RootStateOrAny) => state.hoverOverNavBar
  );
  useEffect(() => {
    if (hoverOverNavBar === false) {
      dispatch(setShowMenu(false));
    }
  }, [hoverOverNavBar]);

  return (
    <nav className={className}>
      <ul>
        <ListItem sub={false} href={homeLocation} text="Home" />
        <ListItem sub={false} href={aboutLocation} text="Over Ons" />
        <ListItem sub={isMobile ? false: footerCheck ? false : true} href={menuCardLocation} text="Menu" />
        <ListItem sub={false} href={galleryLocation} text="Gallerij" />
        <ListItem sub={false} href={contactLocation} text="Contact" />
        <ListItem sub={false} highlight href={tableBooker} text="Reserveer nu!"/>
      </ul>
    </nav>
  );
};

export default Nav;
