import Hero from '../../../components/elements/hero'
// import InViewTracker from '../../../components/trackers/InView'
import React, { ReactNode } from 'react'
import Navigation from '../../elements/navigation'
import InViewTracker from 'src/components/meta/inViewTracker';


const Header = (props:{front?:boolean,children?:ReactNode}) => {
    const {front} = props;
    return (
        <header>
            <Navigation/>
                {!front && <><InViewTracker string="header"/><Hero/></>}
        </header>
    )
}

export default Header;