import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { homeLocation } from "src/constants/routing";
import { IBreadcrumbs } from "./type";



const BreadcrumbsComponent: React.FC<IBreadcrumbs> = ({ menu, menuText, sub, subText }) => {
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: string
  ) => {
    event.preventDefault();
    window.location.href = item;
  }
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link
        color="inherit"
        href={homeLocation}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleClick(e, homeLocation)}
      >
        Home
      </Link>
      <Link color="inherit" href={menu} onClick={(e) => handleClick(e, menu)}>
        {menuText}
      </Link>
      <Link
        color="textPrimary"
        href={sub}
        onClick={(e) => handleClick(e, sub)}
        aria-current="page"
      >
        {subText}
      </Link>
    </Breadcrumbs>
  );
}
export default BreadcrumbsComponent;