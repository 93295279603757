import React from "react";
import Head from "src/components/meta/head";
import ScrollAnimation from "react-animate-on-scroll";
import Header from "src/components/sections/header";
import About from "src/components/sections/about";
import Contact from "src/components/sections/contact";
import Footer from "src/components/sections/footer";
import Spotlight from "src/components/sections/spotlight";
import Menu from "src/components/sections/menu";
import Gallery from "src/components/sections/gallery";
import { RootStateOrAny, useSelector } from "react-redux";
const Home: React.FC = () => {
    const about = useSelector((state:RootStateOrAny) => state.components.about)
  return (
    <>
      <Head title="Homepage" description={about.description} />
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={0.4}>
        <Header />
        <About />
        <Spotlight />
        <Menu />
        <Gallery />
        <Contact />
        <Footer />
      </ScrollAnimation>
    </>
  );
};

export default Home;
