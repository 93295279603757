/* eslint-disable array-callback-return */
import { homeLocation, menuCardLocation } from "../../../../constants/routing";

import { useEffect, useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Animated } from "react-animated-css";
import { setBurgerClick } from "../../../../store/actions";
import {IListItem} from './type'

const ListItem: React.FC<IListItem> = ({ href, text, sub, highlight = false }) => {
  const dispatch = useDispatch();
  const linkRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const inView = useSelector((state: RootStateOrAny) => state.inView);
  const navBar = useSelector((state: RootStateOrAny) => state.navBar);
  const burger = useSelector((state: RootStateOrAny) => state.burger);
  const menuCardItems = useSelector(
    (state: RootStateOrAny) => state?.menuCardItems
  );

  useEffect(() => {
    if (inView === href.substring(1)) {
      linkRef.current && linkRef.current.classList.add("active");
    }
    if (inView === "header" && href === homeLocation) {
      linkRef.current && linkRef.current.classList.add("active");
    }

    return () => {
      linkRef.current && linkRef.current.classList.remove("active");
    };
    
  }, [inView, href, text, sub]);

  const showSubMenu = () => {
    if (sub) {
      setShowMenu(true);
    }
  };

  useEffect(() => {
    if (navBar === false) {
      setShowMenu(false);
    }
  }, [navBar]);

  return (
    <li ref={linkRef} onMouseEnter={() => showSubMenu()}>
      <a onClick={() => dispatch(setBurgerClick(!burger))} href={href} className={highlight ? "anchorHighlight" : ''}>
        {text}
      </a>
      {sub ? <KeyboardArrowDownIcon /> : undefined}
      {sub && (
        //@ts-ignore
        <Animated
          animationIn="fadeInDown"
          animationOut="fadeOutDown"
          animateOnMount={false}
          isVisible={showMenu}
          animationInDuration={500}
          animationOutDuration={500}
        >
          <ul className="__sub_menu">
            {menuCardItems &&
              menuCardItems.map((item, i) => {
                if (item.acf.gerechten_selecteren.length > 0) {
                  return (
                    <li>
                      <a href={menuCardLocation + "/" + item.id}>
                        {item.acf.menu_titel}
                      </a>
                    </li>
                  );
                }
              })}
          </ul>
        </Animated>
      )}
    </li>
  );
};

export default ListItem;
