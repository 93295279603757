import Box from "@material-ui/core/Box";
import { Container, Grid } from "@material-ui/core";

import { RootStateOrAny, useSelector } from "react-redux";
import Detail from "./detail";

import InViewTracker from "src/components/meta/inViewTracker";
import Markdown from "src/components/elements/markdown";

export default function Spotlight() {
  const spotlight = useSelector(
    (state: RootStateOrAny) => state.components.spotlight
  );
  const spotlightItems = useSelector(
    (state: RootStateOrAny) => state.spotlightItems
  );
  return (
    <section className="pt-5">
      <Box className="restaurant__spotlight">
        <Container>
          <Box>
            <h2 className="text-white pt-5">{spotlight.title}</h2>
            <Box className="mb-5">
              <Markdown input={spotlight.description} />
            </Box>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              {spotlightItems?.map((item, i) => {
                const { title, description, price, dish_image } = item.acf;
                const image = dish_image? dish_image : undefined
                if (i < 4) {
                  return (
                    <Detail
                      key={i}
                      thumbnail={image.sizes["medium_large"]}
                      name={title}
                      description={description}
                      cardName={title}
                      id={item.id}
                    />
                  );
                }
              })}
            </Grid>
          </Box>
        </Container>
      </Box>
      <InViewTracker string="menu" />
    </section>
  );
}
