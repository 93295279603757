import Slider from '../slider'
import { Box } from '@material-ui/core'

const Hero:React.FC<{}> = () => {
    return (
        <div id="hero">
            <Box className="hero__slider_container">
                <Slider />
            </Box>
        </div>
    )
}

export default Hero
