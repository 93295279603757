import React from "react";

import { RootStateOrAny, useSelector } from "react-redux";
import { Container, Grid, Box, Button } from "@material-ui/core";
import FormComponent from "../../elements/form";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import Map from "../../elements/map";
import InViewTracker from "../../meta/inViewTracker";
import OpeningsHours from "src/components/sections/openings-hours";
import Markdown from "src/components/elements/markdown";

const Contact = () => {
  const contact = useSelector(
    (state: RootStateOrAny) => state.components.contact
  );
  const handleClick = (href) => {
    window.open(href);
  };

  return (
    <>
      <section id="contact" className="contact__information py-5">
        <InViewTracker string="contact" />
        <Box>
          <Container>
            <Box className="d-flex justify-content-center flex-column align-items-center">
              <h2 className="pt-5">{contact.title}</h2>
              <Markdown input={contact.description} />
            </Box>
            <Grid container className="mt-5">
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box className="d-flex flex-column justify-content-center align-items-center text-center">
                  <h4>Contact Informatie</h4>
                  <ul className="font-size-reg font-weight-300">
                    <li><a href={"https://goo.gl/maps/De4HxYQk1maeVBFz5"}>{contact.adres}</a></li>
                    <li className="text-secondary-color my-4">
                      <a className="text-white" href={"tel:" + contact.phone}>
                        {contact.phone}
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white"
                        href={"mailto:" + contact.email}
                      >
                        {contact.email}
                      </a>
                    </li>
                  </ul>
                  <div className="d-flex justify-content-start">
                    <Button
                      onClick={() => handleClick(contact.facebook)}
                      className="contact__media_button"
                    >
                      <FacebookIcon fontSize="large" className="text-white" />
                    </Button>
                    <Button
                      onClick={() => handleClick(contact.instagram)}
                      className="contact__media_button"
                    >
                      <InstagramIcon fontSize="large" className="text-white" />
                    </Button>
                  </div>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <OpeningsHours />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormComponent />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </section>
         <Map width={"100%"}/>
    </>
  );
};

export default Contact;
