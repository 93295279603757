import { Container } from "@material-ui/core";
import Footer from "src/components/sections/footer";
import Header from "src/components/sections/header";
import GalleryComponent from "src/components/sections/gallery"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setInView } from "src/store/actions";
import Head from "src/components/meta/head";

const Gallery: React.FC = () => {
  const gallery = useSelector((state:RootStateOrAny) => state.components.gallery)
  const dispatch = useDispatch();
  
  dispatch(setInView("gallery"));
  return (
    <>
    <Head title={gallery.title} description={gallery.description}/>
    <Header front={true} />
      <Container>
         <GalleryComponent/>
      </Container>
      <Footer/>
    </>
  );
};

export default Gallery;
