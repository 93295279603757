import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { getAsyncComponents } from 'src/services/get';
import { setComponentItems } from 'src/store/actions';

export function UseComponents() {
    const dispatch = useDispatch();
    useEffect(() => {
        getAsyncComponents()
        .then((result) => dispatch(setComponentItems(result)))
        
      },[])
    return (<></>)
}
