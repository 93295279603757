
export const homeLocation = '/'
export const aboutLocation = '/about'
export const menuCardLocation = '/menu'
export const dishLocation = '/gerechten'
export const galleryLocation = '/gallery'
export const tourLocation = '/tour'
export const contactLocation = '/contact'
export const privacyLocation = '/privacy-policy'
export const termsLocation = '/terms-of-service'
export const cookieLocation = '/cookies'
export const siteMapLocation = '/site-map'
export const tableBooker = 'https://reservations.tablebooker.com?restaurantId=36708881'



