import ScrollAnimation from "react-animate-on-scroll";
export default function Image(props) {
  const randomNumber = Math.floor(Math.random() * 10) / 10;
  return (
    <ScrollAnimation
      animateOnce={true}
      animateIn="fadeIn"
      duration={randomNumber}
    >
      <img src={props.src} alt={props.alt} />
    </ScrollAnimation>
  );
}
